<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  cancellingActions,
  finishingActions,
  startingActions,
} from './const/bookingActions';

export default {
  props: {
    hasStarted: {
      type: Boolean,
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.planning.edit, {
      bookingUuid: state => state.booking.data.uuid,
      isUnavailability: state => get(state, 'booking.data.isUnavailability'),
      startingActionsStatus: state => state.startingActions.STATUS,
      finishingActionsStatus: state => state.finishingActions.STATUS,
      bypassCodeStatus: state => state.bypassCode.STATUS,
      cancellingActionsStatus: state => state.cancellingActions.STATUS,
    }),
    title() {
      return this.isUnavailability ? 'Unavailability action' : 'Other booking actions';
    },
    unavailabilityDropdownTitle() {
      return this.hasStarted ? 'Finishing' : 'Cancelling';
    },
    unavailabilityActionTitle() {
      return this.hasStarted ? 'Finish now' : 'Cancel unavailability';
    },
    unavailabilityActions() {
      return [{
        label: this.unavailabilityActionTitle,
        action: this.unavailabilityAction,
      }];
    },
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carsharing.planning.edit, [
      'patchActivateBooking',
      'patchResendBooking',
      'postBookingBypassCode',
      'patchTerminateBooking',
      'patchTerminateByAdminBooking',
      'patchCancelBooking',
      'deleteUnavailability',
      'patchFinishUnavailability',
    ]),
    cancellingActions,
    finishingActions,
    startingActions,
    async cancelBooking(applyFees) {
      await this.patchCancelBooking({
        bookingUuid: this.bookingUuid,
        data: {
          applyFees,
        },
      });

      if (this.cancellingActionsStatus.LOADED) {
        this.callback();
      }
    },
    async unavailabilityAction() {
      const action = this.hasStarted ? this.patchFinishUnavailability : this.deleteUnavailability;
      await action(this.bookingUuid);

      if (this.cancellingActionsStatus.LOADED) {
        this.callback();
      }
    },
  },
};
</script>
<template>
  <div
    class="BookingActionsComponent"
    data-test-id="booking_actions"
  >
    <label class="d-block emobg-font-weight-bold mb-2">
      {{ title }}
    </label>
    <div
      v-if="isUnavailability"
      class="row"
    >
      <div class="col-4">
        <ui-dropdown
          :color="COLORS.danger"
          :text-color="GRAYSCALE.white"
          :loading="cancellingActionsStatus.LOADING"
          arrow
          data-test-id="unavailability-list"
          class="BookingActionsComponent__dropdown"
        >
          <p
            slot="trigger"
            class="pl-3 w-100 emobg-font-weight-semibold"
          >
            {{ unavailabilityDropdownTitle }}
          </p>
          <ui-dropdown-actions
            slot="content"
            :actions.prop="unavailabilityActions"
            class="emobg-color-ink"
          />
        </ui-dropdown>
      </div>
    </div>
    <div
      v-else
      class="row"
    >
      <div class="col-4">
        <ui-dropdown
          :color="COLORS.primary"
          :face="FACES.outline"
          :loading="startingActionsStatus.LOADING"
          arrow
          data-test-id="starting-list"
          class="BookingActionsComponent__dropdown"
        >
          <p
            slot="trigger"
            class="pl-3 w-100 emobg-font-weight-semibold"
          >
            Starting
          </p>
          <ui-dropdown-actions
            slot="content"
            :actions.prop="startingActions()"
            class="emobg-color-ink"
          />
        </ui-dropdown>
      </div>
      <div class="col-4">
        <ui-dropdown
          :color="COLORS.danger"
          :face="FACES.outline"
          :loading="finishingActionsStatus.LOADING || bypassCodeStatus.LOADING"
          arrow
          data-test-id="cancelling-list"
          class="BookingActionsComponent__dropdown"
        >
          <p
            slot="trigger"
            class="pl-3 w-100 emobg-font-weight-semibold"
          >
            Finishing
          </p>
          <ui-dropdown-actions
            slot="content"
            :actions.prop="finishingActions(hasStarted)"
            class="emobg-color-ink"
          />
        </ui-dropdown>
      </div>
      <div class="col-4">
        <ui-dropdown
          :color="COLORS.danger"
          :text-color="GRAYSCALE.white"
          :loading="cancellingActionsStatus.LOADING"
          :disabled="hasStarted"
          arrow
          data-test-id="cancelling-list"
          class="BookingActionsComponent__dropdown"
        >
          <p
            slot="trigger"
            class="pl-3 w-100 emobg-font-weight-semibold"
          >
            Cancelling
          </p>
          <ui-dropdown-actions
            slot="content"
            :actions.prop="cancellingActions(hasStarted || cancellingActionsStatus.LOADING)"
            class="emobg-color-ink"
          />
        </ui-dropdown>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.BookingActionsComponent__dropdown {
  .Ui-Dropdown {
    min-width: 100%;

    &__trigger {
      width: 140px;
    }
  }
}

</style>
