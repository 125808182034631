<script>
import { getValue } from '@emobg/web-utils';
import { mapMutations, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { getMessageText } from '@/components/GlobalMessaging/GlobalMessagingComponent';

export default {
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.planning.create, {
      bookingError: state => getValue(state, 'booking.error', null),
      vehicleLocationsError: state => getValue(state, 'locations.error', null),
    }),
    ...mapState(DOMAINS_MODEL.carsharing.planning.edit, {
      editBookingError: state => getValue(state, 'error', null),
      getEditBookingError: state => getValue(state, 'booking.error', null),
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),
    error() {
      return this.bookingError
        || this.editBookingError
        || this.vehicleLocationsError
        || this.getEditBookingError;
    },
  },
  created() {
    this.getMessageText = getMessageText;
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.carsharing.planning.create, [
      'clearBookingError',
    ]),
    ...mapMutations(DOMAINS_MODEL.carsharing.planning.edit, [
      'clearEditBookingError',
    ]),
    clearErrors() {
      this.clearBookingError();
      this.clearEditBookingError();
    },
  },
};
</script>
<template>
  <div
    v-if="error"
    class="w-100 position-relative"
    data-test-id="booking_actions_feedback"
  >
    <ui-alert
      :color="COLORS.danger"
      :dismissible="!getEditBookingError"
      :icon="ICONS.infoFull"
      class="animated fadeIn d-block pb-3 w-100"
      data-test-id="alert"
      @dismissAlert="clearErrors()"
    >
      <p v-html="getMessageText(error, $t, operatorTimezone)" />
    </ui-alert>
  </div>
</template>
